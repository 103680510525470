<template>
  <div>
    <!--  modal  -->
    <el-dialog v-model="dialogFormVisible" title="Create new Doctor" class="appointment-status-modal" top="1vh">
      <DoctorForm @dialogClose="dialogClose" @selectedDoctor="selectedDoctor"/>
    </el-dialog>
  </div>
</template>

<script>
import DoctorForm from '../form/DoctorForm';

export default {
  name: 'AddDoctorModal',
  components: {
    DoctorForm,
  },
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  created() {
    this.dialogFormVisible = true;
  },
  methods: {
    dialogClose() {
      this.dialogFormVisible = false;
    },
    selectedDoctor(value) {
      this.$emit('selectedDoctor', value);
    },
  },
  watch: {
    dialogFormVisible(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('modalClosed');
        this.$emit('getDoctors');
      }
    },
  },
};
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
